// NavigationDotsPlugin.js
import { createModule } from 'yet-another-react-lightbox';
import NavigationDotsModule from './NavigationDotsModule';

const navigationDotsModule = createModule("NavigationDots", NavigationDotsModule, {
});

const NavigationDotsPlugin = ({ addModule }) => {
    addModule(navigationDotsModule);
};

export default NavigationDotsPlugin;
