import React, { createContext, useState, useEffect } from 'react';
import { throttle } from 'lodash';


export const ImageDataContext = createContext();

export const ImageDataProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [currentShoot, setCurrentShoot] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredImages, setFilteredImages] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false); // Manage lightbox state here

  // Set images when they are ready
  useEffect(() => {
    const handleImagesReady = () => {
      if (window.collectedImages) {
        setImages(window.collectedImages);
      }
    };
    document.addEventListener('imagesReady', handleImagesReady);
    return () => {
      document.removeEventListener('imagesReady', handleImagesReady);
    };
  }, []);

  // Handle image clicks and filter images by the selected shoot
  useEffect(() => {
    const throttledSetCurrentIndex = throttle((index) => {
        setCurrentIndex(index);
        console.log(`Throttled index change to: ${index}`);
    }, 500); // Throttle to prevent more than one call per 500 ms

    const handleImageClick = event => {
        console.log('Event received:', event.detail);
        const { shoot, index } = event.detail;
        console.log(`Handling click for shoot=${shoot}, index=${index}`);
        setCurrentShoot(shoot);
        throttledSetCurrentIndex(index); // Use throttled function here
        setLightboxOpen(true);
    };

    window.addEventListener('imageClicked', handleImageClick);
    return () => {
        window.removeEventListener('imageClicked', handleImageClick);
    };
}, []);
  
  // Filter images whenever the current shoot changes and control lightbox opening
  useEffect(() => {
    if (currentShoot) {
      const newFilteredImages = images.filter(img => img.shoot === currentShoot);
      setFilteredImages(newFilteredImages);
      console.log(`Filtered images prepared for shoot ${currentShoot}:`, newFilteredImages);
      if (newFilteredImages.length > 0) {
        console.log(`Opening lightbox with current index: ${currentIndex}`);
        setLightboxOpen(true);
      }
    }
  }, [currentShoot, images, currentIndex]);
  
  return (
    <ImageDataContext.Provider value={{ images, filteredImages, currentShoot, currentIndex, setCurrentIndex, lightboxOpen, setLightboxOpen }}>
      {children}
    </ImageDataContext.Provider>
  );
};