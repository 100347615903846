import React, { useContext, useEffect, useRef, useState } from 'react';
import { ImageDataContext } from '../context/ImageContext';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import 'yet-another-react-lightbox/styles.css';
import './Gallery.css';
import NavigationDotsPlugin from '../components/lightbox/NavigationDotsPlugin'; // Adjust the path as necessary





const Gallery = () => {
  const { filteredImages, currentIndex, setCurrentIndex, lightboxOpen, setLightboxOpen } = useContext(ImageDataContext);
  const fullscreenRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false); // Local state to track fullscreen status

  useEffect(() => {
    // Listener to update isFullscreen state based on the actual fullscreen status
    const handleFullscreenChange = () => {
      const isCurrentlyFullscreen = !!document.fullscreenElement;
      console.log("Fullscreen change detected, status:", isCurrentlyFullscreen);
      setIsFullscreen(isCurrentlyFullscreen);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);


  useEffect(() => {
    console.log(`Preparing to render lightbox with currentIndex: ${currentIndex} and filteredImages:`, filteredImages);
    if (filteredImages.length > 0 && currentIndex !== null) {
     // setTimeout(() => {
        setLightboxOpen(true);
     // }, 100); // Minor delay to ensure data is loaded
    }
  }, [filteredImages, currentIndex, setLightboxOpen]);  // Depend on currentIndex to re-evaluate when it changes
  
  const handleClose = () => {
    console.log("Closing lightbox and resetting...");
    setLightboxOpen(false);
    if (isFullscreen) {
      fullscreenRef.current.exit();
    }

  };

    // Function to handle fullscreen toggle
    const handleFullscreenToggle = () => {
      console.log("Current Fullscreen State: ", isFullscreen); // Log current state
      if (fullscreenRef.current) {
        const action = isFullscreen ? fullscreenRef.current.exit : fullscreenRef.current.enter;
        action();
      }
    };
    

// Custom styling for icons
// eslint-disable-next-line
const iconStyle = {
  width: '24px', // Set a specific width
  height: '24px', // Set a specific height
  fill: 'currentColor', // Ensure the icon uses the current text color
};

// Enter Fullscreen Icon
const EnterFullscreenIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3v2H5v4H3V3h6zm0 16v2H3v-6h2v4h4zm6 0h4v-4h2v6h-6v-2zm0-16v2h4v4h2V3h-6z" />
  </svg>

);

const ExitFullscreenIcon = () => (
  <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5V3H3v6h2V5h4zm6 14v2h6v-6h-2v4h-4zm-6 0H5v-4H3v6h6v-2zm6-14v2h4v4h2V3h-6z" fill="currentColor"/>
  </svg>
);





  console.log("Rendering lightbox with currentIndex:", currentIndex, "and filteredImages:", filteredImages);
  return (
    <div className="Gallery">
    {lightboxOpen && (
      <Lightbox
        open={lightboxOpen}
        close={handleClose}
        slides={filteredImages.map(img => ({ src: img.src }))}
        plugins={[Zoom, Fullscreen, NavigationDotsPlugin ]}
        zoom={{
          maxZoomPixelRatio: 3,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: true
        }}
        fullscreen={{ 
          ref: fullscreenRef, 
          onEnter: () => {
            console.log("Entered fullscreen");
            setIsFullscreen(true);
          },
          onExit: () => {
            console.log("Exited fullscreen");
            setIsFullscreen(false);
          }
        }}
        render={{
          iconZoomIn: () => null,  
          iconZoomOut: () => null, 
          iconEnterFullscreen: () => null, 
          iconExitFullscreen: () => null, 
          buttonFullscreen: ({ isFullscreen }) => (
            <button onClick={handleFullscreenToggle} id="fullscreenbutton">
              {isFullscreen ? <ExitFullscreenIcon /> : <EnterFullscreenIcon />}
            </button>
          ),

        }}
        
        index={currentIndex}
        on={{
          view: ({ index: newIndex }) => {
            console.log(`View changed to index: ${newIndex}`);
            setCurrentIndex(newIndex);
          },
          enterFullscreen: () => {
            console.log("Entered fullscreen");
            setIsFullscreen(true);
          },
          exitFullscreen: () => {
            console.log("Exited fullscreen");
            setIsFullscreen(false);
          },
        }}
      />
    )}
    
  </div>


  );
};

export default Gallery;