//import { useLightboxState,  } from 'yet-another-react-lightbox';
//import { MODULE_CONTROLLER } from 'yet-another-react-lightbox';
import { ImageDataContext } from '../../context/ImageContext';
import './NavigationDotsModule.css'
import React, { useContext } from 'react';

const NavigationDotsModule = ({ children }) => {
    const { filteredImages, currentIndex, setCurrentIndex } = useContext(ImageDataContext);

    const navigateTo = (index) => {
        setCurrentIndex(index); 
    };

    return (
        <>
            {children}  {/* Missing children components */}
            <div style={{ textAlign: 'center', position: 'absolute', bottom: '-15px', width: '100%', paddingBottom:'20px'}} className="navDots">
                {filteredImages.map((_slide, index) => (
                    <button
                        key={index}
                        className="navDot"
                        style={{
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            backgroundColor: currentIndex === index ? 'white' : 'gray',
                            margin: '0 5px',
                            cursor: 'pointer',
                            border: 'none',
                        }}
                        onClick={() => navigateTo(index)}
                        aria-label={`Go to slide ${index + 1}`}
                    />
                ))}
            </div>
        </>
    );
};

export default NavigationDotsModule;

// Assuming addChild function is properly setup to inject this module
// function NavigationDotsPlugin({ addChild }) {
//     addChild(MODULE_CONTROLLER, NavigationDotsModule);
// }

