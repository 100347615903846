import React from 'react';
import Gallery from './components/Gallery';
import { ImageDataProvider } from './context/ImageContext';

const App = () => {
  return (
    <ImageDataProvider>
      <div className="App">
        <Gallery />
      </div>
    </ImageDataProvider>
  );
}

export default App;
